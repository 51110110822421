window.openMenu = function openMenu() {
  let x = document.getElementById('vertical-menu');
  if (x.style.display === 'block') {
    x.style.display = 'none';
  } else {
    x.style.display = 'block';
  }
};

window.closeMenu = function closeMenu() {
  let x = document.getElementById('vertical-menu');
  x.style.display = 'none';
};
